import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import config from '../config';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ContactSupportOutlined } from '@material-ui/icons';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Button, CssBaseline, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  oldPWD:{
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  newPWD:{
    marginTop: theme.spacing(1)
  }
}));

export default function UserInfo({jwt = ""}) {
  const classes = useStyles();
  //const [password, setPassword] = useState<string>("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [accesslevel, setAccesslevel] = useState(0);
  const [changePass, setChangePass] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [infoMessage, setInfoMessage] = useState<string>("");
  const [showPassword1, setShowPassword1] = useState<Boolean>(false);
  const [password1, setPassword1] = useState<string>("");
  const [showPassword2, setShowPassword2] = useState<Boolean>(false);
  const [password2, setPassword2] = useState<string>("");

  const [showOldPassword, setShowOldPassword] = useState<Boolean>(false);
  const [passwordOld, setPasswordOld] = useState<string>("");


  useEffect (() => {
    if(jwt!=""){
        //console.log("New JWT", jwt);
        fetchDetails();
    }
  },[jwt])

  async function fetchDetails(){
    const response = await fetch(config.BACKEND_URL + 'user', {
      method: 'get',
      headers: {
        'Content-type': 'application/json',
        'auth-token': jwt
      }
    });
    const responseBody = await response.json();
    if(response.status==400){
      console.error("Error when fetching user details:", responseBody);
    }
    else{
        //console.log(responseBody);
        setEmail(responseBody.email);
        setName(responseBody.displayname);
        setAccesslevel(responseBody.accesslevel);
        setInfoMessage("");
    }
  }

  const updatePassword = async (evt:any) => {
    evt.preventDefault();
    if(password1!==password2){
      setErrorMessage("Det nye passordet er ikke skrevet inn likt i begge boksene");
      return;
    }

    setErrorMessage("");

    const response = await fetch(config.BACKEND_URL + 'changepwd', {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        'auth-token': jwt
      }, body: JSON.stringify({"oldpwd": passwordOld, "newpwd": password1})
    });
    const responseBody = await response.json();
    if(response.status==400){
      console.error("Error when updating password:", responseBody);
      setErrorMessage("Gammelt passord er ikke korrekt. Vennligt prøv igjen.")
    }
    else{
      console.log("Successfully updated password");
      setPassword1("");
      setPassword2("");
      setPasswordOld("");
      setInfoMessage("Passordet ble oppdatert")
    }

  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Brukerinformasjon</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        <TableRow>
            <TableCell>Navn</TableCell>
            <TableCell>{name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>E-post</TableCell>
            <TableCell>{email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Brukerverifikasjon</TableCell>
            {accesslevel>1 && (
              <TableCell><VerifiedUserIcon/>Verifisert</TableCell>
            )}
            {accesslevel==1 && (
              <TableCell>Ikke verifisert</TableCell>
            )}
          </TableRow>
          {/*cars.map((car) => (
            <TableRow key={car!['id']}>
              <TableCell component="th" scope="row">
                {car!['licenseplate']}
              </TableCell>
              <TableCell align="right">{car!['brand']}</TableCell>
              <TableCell align="right">{car!['model']}</TableCell>
              <TableCell align="right">{car!['color']}</TableCell>
              <TableCell align="right">{car!['status']}</TableCell>
            </TableRow>
          ))*/}
        </TableBody>
      </Table>
    </TableContainer>
    {changePass && (
      <Container className={classes.paper}>
                <Typography component="h1" variant="h5">
          Forandre passord
        </Typography>
        {(errorMessage!=="" && 
          <Alert severity="error">{errorMessage}</Alert>
        )}
        {(infoMessage!=="" && 
          <Alert severity="success">{infoMessage}</Alert>
        )}
        <form className={classes.form} onSubmit={updatePassword}>

          <FormControl fullWidth variant="outlined" className={classes.oldPWD}>
          <InputLabel htmlFor="outlined-adornment-password">Gammelt passord*</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showOldPassword ? 'text' : 'password'}
            value={passwordOld}
            onChange={(evt:any) => {setPasswordOld(evt.target.value)}}
            fullWidth
            required
            autoComplete="current-password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {setShowOldPassword(!showOldPassword)}}
                  onMouseDown={(evt:any) => {evt.preventDefault()}}
                  edge="end"
                >
                  {showOldPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={140}
          />
          </FormControl>

          <FormControl fullWidth variant="outlined"  className={classes.newPWD}>
          <InputLabel htmlFor="outlined-adornment-password">Nytt passord*</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword1 ? 'text' : 'password'}
            autoComplete="new-password"
            value={password1}
            onChange={(evt:any) => {setPassword1(evt.target.value)}}
            fullWidth
            required
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {setShowPassword1(!showPassword1)}}
                  onMouseDown={(evt:any) => {evt.preventDefault()}}
                  edge="end"
                >
                  {showPassword1 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={100}
          />
          </FormControl>

          <FormControl fullWidth variant="outlined"  className={classes.newPWD}>
          <InputLabel htmlFor="outlined-adornment-password">Gjenta nytt passord*</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword2 ? 'text' : 'password'}
            value={password2}
            onChange={(evt:any) => {setPassword2(evt.target.value)}}
            fullWidth
            autoComplete="new-password"
            required
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {setShowPassword2(!showPassword2)}}
                  onMouseDown={(evt:any) => {evt.preventDefault()}}
                  edge="end"
                >
                  {showPassword2 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={150}
          />
          </FormControl>

          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            type="submit"
          >
            Oppdater passord
          </Button>
        </form>
      </Container>
    )}
    </Container>
  );
}