import React from 'react';
import './App.css';
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import { BrowserRouter as Switch, Route } from 'react-router-dom';
import SignUp from './components/Signup';
import Renewpassword from './components/Renewpassword';

function App() {
  return (
    <Switch>
      <Route exact path="/">
        <Login/>
      </Route>
      <Route exact path="/login">
        <Login/>
      </Route>
      <Route exact path="/renewpassword">
        <Renewpassword/>
      </Route>
      <Route path="/signup">
        <SignUp/>
      </Route>
      <Route path="/dashboard/:jwt">
        <Dashboard />
      </Route>
    </Switch>
  );
}


export default App;
