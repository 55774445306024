import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ListCars from './ListCars';
import UserInfo from './UserInfo';
import config from '../config';
import Admin from './Admin';
import { Copyright } from './Copyright';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Opengate from './Opengate';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  iconCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  let { jwt } = useParams<{ jwt: string }>();

  const [accessLevel, setAccessLevel] = React.useState<number>(0);

  const [curView, setCurView] = React.useState<string>("cars");
  const [open, setOpen] = React.useState(false);
  const [firstTime, setFirstTime] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const clickList =(ev:any, button:string) => {
    ev.preventDefault();
    setCurView(button);
    console.log("menu item clicked", button)
  }

  useEffect (() => {
    if(jwt!=""){
        //console.log("New JWT", jwt);
        fetchDetails();
    }
  },[jwt])

  async function fetchDetails(){
    const response = await fetch(config.BACKEND_URL + 'user', {
      method: 'get',
      headers: {
        'Content-type': 'application/json',
        'auth-token': jwt
      }
    });
    const responseBody = await response.json();
    if(response.status==400){
      console.error("Error when fetching user details:", responseBody);
    }
    else{
        //console.log(responseBody);
        setAccessLevel(responseBody.accesslevel);
        setFirstTime(responseBody.firsttime);
    }
  }

  const mainListItems = (
    <div>
      <ListItem button onClick={(ev:any) => {clickList(ev,"account")}}>
        <ListItemIcon>
          <AccountCircleIcon color={curView=="account"? "primary":"inherit"}/>
        </ListItemIcon>
        <ListItemText primary="Brukerdetaljer" />
      </ListItem>
      <ListItem button onClick={(ev:any) => {clickList(ev,"cars")}}>
        <ListItemIcon>
          <DriveEtaIcon color={curView=="cars"? "primary":"inherit"} />
        </ListItemIcon>
        <ListItemText primary="Biler" />
      </ListItem>
      {/*<ListItem button onClick={(ev:any) => {clickList(ev,"stats")}}>
        <ListItemIcon>
          <ShowChartIcon />
        </ListItemIcon>
        <ListItemText primary="Statistics" />
        </ListItem>*/}
        {accessLevel>=2 && (//SupervisorAccountIcon
            <ListItem button onClick={(ev:any) => {clickList(ev,"opengate")}}>
            <ListItemIcon>
              <LockOpenIcon color={curView=="opengate"? "primary":"inherit"}/>
            </ListItemIcon>
            <ListItemText primary="Åpne port" />
          </ListItem>
        )}

        {accessLevel>=10 && (//SupervisorAccountIcon
            <ListItem button onClick={(ev:any) => {clickList(ev,"admin")}}>
            <ListItemIcon>
              <SupervisorAccountIcon color={curView=="admin"? "primary":"inherit"}/>
            </ListItemIcon>
            <ListItemText primary="Admin" />
          </ListItem>
        )}
    </div>
  );
  
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Mine sider
          </Typography>
          {/*<IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>*/}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                {curView==="cars" && (
                  <ListCars jwt={jwt}/>
                )}
                {curView==="account"&& (
                  <UserInfo jwt={jwt}/>
                )}
                {curView==="admin"&& (
                  <Admin jwt={jwt} accesslevel={accessLevel}/>
                )}
                {curView==="opengate"&& (
                  <Opengate jwt={jwt}/>
                )}
              </Paper>
              
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>

        <Dialog
        open={firstTime}
        onClose={() => {setFirstTime(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Velkommen til iSpotparking brukerside"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className={classes.iconCenter}>
          <ThumbUpIcon style={{ fontSize:78, color: green[500] }}/>
          </div>
            Velkommen til administrasjon av din parkering i garasjehuset i Arbos gate. Her kan du
            <ul>
                  <li>Administrere din brukerprofil.</li>
                  <li>Legge til og administrere din(e) bil(er), slik at porten automatisk åpner seg for deg.</li>
                  <li>Åpne porten ved hjelp av nøkkelknappen nederst til venstre på siden.</li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setFirstTime(false)}} color="primary" autoFocus>
            Start
          </Button>
        </DialogActions>
      </Dialog>
      </main>
    </div>
  );
}