import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import config from '../config';
import { Redirect } from 'react-router-dom';
import { Copyright } from './Copyright';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [invalidMsg, setInvalidMsg] = useState<string>("");
  const [jwt, setJWT] = useState<string>("");

  const handleMouseDownPassword = (event:any) => {
    event.preventDefault();
  };

  const handlePwdChange = (event:any) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (evt:any) => {
    evt.preventDefault();
    console.log("Registering", email);
    const response = await fetch(config.BACKEND_URL + 'signup', {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        displayname: name,
        password: password,
        secret: "oslogarasje"
      }),
    });
    const responseBody = await response.json();
    if(responseBody.usrsignedup){
      setJWT(responseBody.token);
      console.log("Successfully logged in");
    }
    else{
      if(responseBody.reason==="Wrong secret provided"){
        setInvalidMsg("Feil hemmelig beskjed gitt");
        console.log("Wrong secret provided");
      }
      else{
        setInvalidMsg("E-post adressen er allerede registrert");
        console.log("Invalid email/password provided");
      }
    }
  }

  if(jwt!=""){
    return (
      <Redirect
      to={{
        pathname: '/dashboard/' + jwt,
      }}
    />
    );
  }
else{
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Registrer deg
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="name"
                name="Name"
                variant="outlined"
                required
                fullWidth
                value={name}
                onChange={(evt:any) => {setName(evt.target.value)}}
                id="name"
                label="Navn"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                value={email}
                onChange={(evt:any) => {setEmail(evt.target.value)}}
                id="email"
                label="E-post adresse"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Passord*</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handlePwdChange}
            fullWidth
            required
            autoComplete="new-password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {setShowPassword(!showPassword)}}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
          </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="Jeg tillater at jeg kan bli kontaktet via e-post om nye tjenester som kan være av interesse for meg."
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            type="submit"
          >
            Registrer deg
          </Button>
          {(invalidMsg!=="" && 
          <Alert severity="error">{invalidMsg}</Alert>
        )}
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="login" variant="body2">
                Allerede registrert? Logg inn
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
          }
}