import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import config from '../config';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ContactSupportOutlined } from '@material-ui/icons';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  button: {
    margin: theme.spacing(0),
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  margin: {
    margin: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ListCars({jwt = ""}) {
  const classes = useStyles();
  //const [password, setPassword] = useState<string>("");
  const [cars, setCars] = useState([]);
  const [confirmDel, setConfirmDel] = useState<string>("");

  const [plate, setPlate] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [color, setColor] = useState("");
  const [nick, setNick] = useState("");
  const [redir, setRedir] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect (() => {
    if(jwt!=""){
        //console.log("New JWT", jwt);
        listCars();
    }
  },[jwt])

  async function listCars(){
    const response = await fetch(config.BACKEND_URL + 'cars', {
      method: 'get',
      headers: {
        'Content-type': 'application/json',
        'auth-token': jwt
      }
    });
    const responseBody = await response.json();
    if(response.status==400){
      console.error("Error when fetching cars:", responseBody);
    }
    else{
        console.log(responseBody);
        setCars(responseBody);
    }
  }

  async function deleteCar(){
    const response = await fetch(config.BACKEND_URL + 'deletecar', {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        'auth-token': jwt
      }, body: JSON.stringify({"licenseplate": confirmDel})
    });
    const responseBody = await response.json();
    setConfirmDel("");
    if(response.status==400){
      console.error("Error when deleting car:", responseBody);
    }
    else{
      console.log("Successfully deleted");
      listCars();
    }
  }
  async function addCar(){
    if(plate==""){//at the very least we require some input into the license plate
        setErrorMessage("Skiltnr. er et påkrevd felt.");
        return;
    }

    var skiltnr:string = plate.replace(/\s+/g, '');
    skiltnr = skiltnr.toLocaleUpperCase();

    const response = await fetch(config.BACKEND_URL + 'addcar', {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        'auth-token': jwt
      }, body: JSON.stringify({"nickname": nick,"licenseplate": skiltnr, "brand": brand, "model": model, "color": color})
    });
    const responseBody = await response.json();
    if(response.status==400){
      console.error("Error when adding car:", responseBody);
      setErrorMessage("Det oppstod en feil ved lagring av bil. Vennligst kontakt support.");
    }
    else{
        console.log(responseBody);
        setPlate("");
        setBrand("");
        setModel("");
        setColor("");
        setNick("");
        setErrorMessage("");
        listCars();

    }
  }

  return (
    <Container>
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Kallenavn</TableCell>
            <TableCell align="right">Skiltnr.</TableCell>
            <TableCell align="right">Merke</TableCell>
            <TableCell align="right">Modell</TableCell>
            <TableCell align="right">Farge</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cars.map((car) => (
            <TableRow key={car!['id']}>
              <TableCell align="left">{car!['nickname']}</TableCell>
              <TableCell align="right" component="th" scope="row">
                {car!['licenseplate']}
                <IconButton aria-label="delete" className={classes.margin} onClick={() => setConfirmDel(car!['licenseplate'])}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </TableCell>
              <TableCell align="right">{car!['brand']}</TableCell>
              <TableCell align="right">{car!['model']}</TableCell>
              <TableCell align="right">{car!['color']}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog
                open={confirmDel!==""}
                onClose={() => {setConfirmDel("")}}
                aria-labelledby="dialog-cancel-sub"
                aria-describedby="dialog-cancel-sub-description"
              >
                <DialogTitle id="alert-dialog-title">{"Slette bil?"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Vennligst bekreft at du vil slette {confirmDel} fra dine registrerte biler.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => {setConfirmDel("")}} color="primary">
                    Lukk
                  </Button>
                  <Button onClick={() => {deleteCar()}} color="primary" autoFocus>
                    Slett bil
                  </Button>
                </DialogActions>
              </Dialog>
    </TableContainer>
     <Container maxWidth="lg" component="main">
     <Grid container spacing={1}>
  <Grid item xs={12}>
    <Typography className={classes.heading}>Legg til ny bil:</Typography>
    </Grid>



    <Grid item xs={6}>
    <TextField
        variant="outlined"
        required
        fullWidth
        id="licenseplate"
        label="Skiltnr."
        name="plate"
        onChange={(evt:any) => {setPlate(evt.target.value);}}
        value={plate}
      />
    </Grid>
    <Grid item xs={6}>
    <TextField
        variant="outlined"
        fullWidth
        id="brand"
        label="Merke"
        name="brand"
        onChange={(evt:any) => {setBrand(evt.target.value);}}
        value={brand}
      />
    </Grid>
    <Grid item xs={6}>
    <TextField
        variant="outlined"
        fullWidth
        id="model"
        label="Modell"
        name="model"
        onChange={(evt:any) => {setModel(evt.target.value);}}
        value={model}
      />
    </Grid>
    <Grid item xs={6}>
    <TextField
        variant="outlined"
        fullWidth
        id="color"
        label="Farge"
        name="color"
        onChange={(evt:any) => {setColor(evt.target.value);}}
        value={color}
      />
    </Grid>

    <Grid item xs={12}>
    <TextField
        variant="outlined"
        fullWidth
        id="nickname"
        label="Kallenavn"
        name="nickname"
        onChange={(evt:any) => {setNick(evt.target.value);}}
        value={nick}
      />
    </Grid>

    <Grid item xs={12}>
    <Button
    variant="contained"
    color="primary"
    className={classes.button}
    startIcon={<AddIcon />}
    fullWidth
    onClick={addCar}
  >
    Legg til bil
  </Button>
  {(errorMessage!=="" &&
    <Alert severity="error">{errorMessage}</Alert>
  )}
    </Grid>

  
  </Grid>

</Container>
</Container>

        
  );
}