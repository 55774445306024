import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Alert } from '@material-ui/lab'
import config from '../config';
import { Redirect } from 'react-router-dom';
import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import { Copyright } from './Copyright';

const useStyles = makeStyles((theme:any) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    
  },
}));



export default function Renewpassword() {
  const classes = useStyles();
  const [email, setEmail] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [infoMessage, setInfoMessage] = useState<string>("");

  const handleEmailChange = (event:any) => {
    setEmail(event.target.value);
  };

  const resetpwd = async (evt:any) => {
      evt.preventDefault();
      console.log("Resetting password");
      const response = await fetch(config.BACKEND_URL + 'forgot-pwd', {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        }),
      });
      const responseBody = await response.json();
      setInfoMessage("Passord tilbakestilt for bruker. Du vil få en epost med videre instruksjoner innen 24 timer.")
  }

  return (

    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>
        {(errorMessage!=="" && 
          <Alert severity="error">{errorMessage}</Alert>
        )}
        <Typography component="h1" variant="h5">
          Glemt passord?
        </Typography>
        <form className={classes.form} onSubmit={resetpwd}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-post adresse"
            value={email}
            onChange={handleEmailChange}
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            type="submit"
          >
            Tilbakestill passord
          </Button>
          {(infoMessage!=="" && 
          <Alert severity="info">{infoMessage}</Alert>
        )}
                  <Grid container justify="flex-end">
            <Grid item>
              <Link href="login" variant="body2">
                Tilbake til innlogging
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}