import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import config from '../config';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ContactSupportOutlined } from '@material-ui/icons';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Button, CssBaseline, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  table: {
    minWidth: 500,
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
}));

export default function Admin({jwt = "", accesslevel = 0}) {
  const classes = useStyles();
  //const [password, setPassword] = useState<string>("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [changePass, setChangePass] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [infoMessage, setInfoMessage] = useState<string>("");
  const [showPassword1, setShowPassword1] = useState<Boolean>(false);
  const [password1, setPassword1] = useState<string>("");
  const [showPassword2, setShowPassword2] = useState<Boolean>(false);
  const [password2, setPassword2] = useState<string>("");

  const [showOldPassword, setShowOldPassword] = useState<Boolean>(false);
  const [passwordOld, setPasswordOld] = useState<string>("");

  const [userList, setUserList] = useState([]);


  useEffect (() => {
    if(jwt!=""){
        //console.log("New JWT", jwt);
        fetchUsers();
    }
  },[jwt])

  async function fetchUsers(){
    const response = await fetch(config.BACKEND_URL + 'get-all-users', {
      method: 'get',
      headers: {
        'Content-type': 'application/json',
        'auth-token': jwt
      }
    });
    const responseBody = await response.json();
    if(response.status==400){
      console.error("Error when fetching users:", responseBody);
    }
    else{
        //console.log(responseBody);
        //console.log("Received user list", responseBody);
        setUserList(responseBody);
    }
  }

  async function updateAccess(newaccess:number, userid:number){
    if(newaccess>=accesslevel){
      setErrorMessage("Kan ikke sette brukernivå høyere enn du selv har");
      return;
    }

    setErrorMessage("");

    const response = await fetch(config.BACKEND_URL + 'set-user-access', {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        'auth-token': jwt
      }, body: JSON.stringify({"userid": userid, "newaccess": newaccess})
    });
    const responseBody = await response.json();
    if(response.status==400){
      console.error("Error when updating user:", responseBody);
      setErrorMessage("En feil oppstod når brukertilgang skulle oppdateres.")
    }
    else{
      console.log("Successfully updated user access level");
      setInfoMessage("Brukertilgang for bruker ble oppdatert");
      fetchUsers();
    }

  }

  function acccessToString(accesslevel:number):string{
    if(accesslevel===1){
        return "Registered";
    }
    else if(accesslevel===2){
        return "Authorized";
    }
    else if(accesslevel===10){
        return "Admin";
    }
    else if(accesslevel>10){
        return "Dev";
    }
    else{
        return "Unknown";
    }
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>, id:number) => {
    //console.log(event.target.value as string);
    //console.log("id", id);
    updateAccess(event.target.value as number, id);
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Typography className={classes.heading}>Forklaring:</Typography>
        <ol>
            <li><b>Registrert</b> Betyr at brukeren er registrert men bilskilt som hen har registrert vil <u>ikke</u> bli en del av CSV-filen som genereres.</li>
            <li><b>Verifisert</b> Betyr at brukeren er registrert og bilskilt som hen har registrert vil bli en del av CSV-filen som genereres.</li>
            <li><b>Admin</b> Kan verifisere brukere slik at bilskiltene dems blir en del av CSV-filen.</li>
            <li><b>Dev</b> Utviklertilgang.</li>
        </ol>
      {(errorMessage!=="" &&
    <Alert severity="error">{errorMessage}</Alert>
  )}
    <TableContainer>
      <Table aria-label="simple table" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Navn</TableCell>
            <TableCell>E-post</TableCell>
            <TableCell>Tilgangsnivå</TableCell>
          </TableRow>
        </TableHead>
        {userList.map((user) => (
            <TableRow key={user!['id']}>
              <TableCell align="left">{user!['displayname']}</TableCell>
              <TableCell>{user!['email']}</TableCell>
              <TableCell align="right">
              <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          disabled={Number(user!['accesslevel'])>=accesslevel}
          value={user!['accesslevel']}
          onChange={(ev:React.ChangeEvent<{ value: unknown }>) => {handleChange(ev, user!['id'])}}
        >
          <MenuItem value={1}>Registrert</MenuItem>
          <MenuItem value={2}>Verifisert</MenuItem>
          <MenuItem value={10}>Admin</MenuItem>
          <MenuItem value={11}>Dev</MenuItem>
        </Select>
                  </TableCell>
            </TableRow>
          ))}
        <TableBody>

          {/*
          <TableRow>
            <TableCell>E-post</TableCell>
            <TableCell>{email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Brukerverifikasjon</TableCell>
            {accesslevel>1 && (
              <TableCell><VerifiedUserIcon/>Verifisert</TableCell>
            )}
            {accesslevel==1 && (
              <TableCell>Ikke verifisert</TableCell>
            )}
          </TableRow>
          {/*cars.map((car) => (
            <TableRow key={car!['id']}>
              <TableCell component="th" scope="row">
                {car!['licenseplate']}
              </TableCell>
              <TableCell align="right">{car!['brand']}</TableCell>
              <TableCell align="right">{car!['model']}</TableCell>
              <TableCell align="right">{car!['color']}</TableCell>
              <TableCell align="right">{car!['status']}</TableCell>
            </TableRow>
          ))*/}
        </TableBody>
      </Table>
    </TableContainer>
    
    </Container>
  );
}