import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import config from '../config';
import { Button, Grid} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import LockOpenIcon from '@material-ui/icons/LockOpen';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  button: {
    margin: theme.spacing(0),
  },
}));

export default function Opengate({jwt = ""}) {
  const classes = useStyles();
  //const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  useEffect (() => {
    if(jwt!==""){
        //console.log("New JWT", jwt);
        //listCars();
    }
  },[jwt])

  async function openGate(){
    setSuccessMessage("");
    setErrorMessage("");
    const response = await fetch(config.BACKEND_URL + 'opengate', {
      method: 'get',
      headers: {
        'Content-type': 'application/json',
        'auth-token': jwt
      }
    });
    const responseBody = await response.json();
    if(response.status===400){
      console.error("Error when opening gate:", responseBody);
      setErrorMessage(responseBody);
    }
    else{
        console.log(responseBody);
        setSuccessMessage("Porten ble åpnet!");

    }
  }

  return (
   
     <Container maxWidth="lg" component="main">
     <Grid container spacing={1}>
  <Grid item xs={12}>
    <Typography className={classes.heading}>Trykk knappen under for åpne garasjeporten:</Typography>
    </Grid>


    <Grid item xs={12}>
    <Button
    variant="contained"
    color="secondary"
    className={classes.button}
    startIcon={<LockOpenIcon />}
    fullWidth
    onClick={openGate}
  >
    Åpne port
  </Button>
  {(errorMessage!=="" &&
    <Alert severity="error">{errorMessage}</Alert>
  )}
      {(successMessage!=="" &&
    <Alert severity="success">{successMessage}</Alert>
  )}

    </Grid>

  
  </Grid>

</Container>

        
  );
}